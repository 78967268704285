<template>

  <div class="d-flex flex-row align-center" v-if="labelsAsArray.length > 1">
    <v-btn
        v-for="(label,i) in labelsAsArray" :key="i+1"
        :x-small="xSmall"
        :small="small"
        :large="large"
        :x-large="xLarge"
        tile
        text
        class="py-1 px-2"
        :class="{
          [color+' white--text']: indexSelected == i,
          'elevation-2': indexSelected == i,
          'v-btn--outlined': indexSelected != i && outlined,
          'rounded-l': i == 0,
          'rounded-r': i == labelsAsArray.length-1,
          'disable-active': disableActive && indexSelected == i
        }"
        :disabled="disableActive && indexSelected == i"
        @click="select(i)">

      <slot
          v-if="indexSelected == i"
          name="prepend-selected-item"
          v-bind:value="value"
      >
      </slot>

      <slot
          name="label"
          :label="label"
          :selected="indexSelected == i"
      >
        {{ label }}
      </slot>

      <slot
          v-if="indexSelected == i"
          name="append-selected-item"
          v-bind:value="value"
      >

      </slot>

    </v-btn>

  </div>

</template>

<script>
export default {
  name: "VSwitchText",
  props: {
    value: {
      type: [Number, String, Boolean],
      default: 0
    },
    asToggle: {
      type: Boolean,
      default: false
    },
    disableActive: {
      type: Boolean,
      default: false
    },
    color: String,
    labels: [Array, Object],
    default: Number,
    outlined: Boolean,
    xSmall: Boolean,
    small: Boolean,
    large: Boolean,
    xLarge: Boolean
  },
  computed: {
    indexSelected: function () {
      let value = this.value
      if (this.asToggle) {
        value = value == false ? "0" : "1"
      }
      let index = Object.keys(this.labels).indexOf(value)
      return index === -1 ? null : index
    },
    labelsAsArray: function () {
      return Object.values(this.labels)
    }
  },
  data() {
    return {
      model: this.value
    }
  },
  methods: {
    select: function (val) {
      let realVal = Object.keys(this.labels)[val]
      this.$emit('input', realVal)
    }
  },
  mounted() {
    if (this.asToggle && Object.values(this.labels).length > 2) {
      throw new Error("Because of the 'asToggle' is set to true, the 'labels' array could not have more than two values")
    }
  }
}
</script>

<style scoped>
.v-switch-text {
  width: 100px;
}

.v-btn.v-btn--disabled.disable-active {
  color: white !important;
}
</style>