export default {
    getLocaleFormat(locale) {
        let formats = {

            "af": "yyyy/MM/dd",
            "af-ZA": "yyyy/MM/dd",

            "am": "d/M/yyyy",
            "am-ET": "d/M/yyyy",

            "ar": "dd/MM/yyyy",
            "ar-AE": "dd/MM/yyyy",
            "ar-BH": "dd/MM/yyyy",
            "ar-DZ": "dd-MM-yyyy",
            "ar-EG": "dd/MM/yyyy",
            "ar-IQ": "dd/MM/yyyy",
            "ar-JO": "dd/MM/yyyy",
            "ar-KW": "dd/MM/yyyy",
            "ar-LB": "dd/MM/yyyy",
            "ar-LY": "dd/MM/yyyy",
            "ar-MA": "dd-MM-yyyy",
            "ar-OM": "dd/MM/yyyy",
            "ar-QA": "dd/MM/yyyy",
            "ar-SA": "dd/MM/yy",
            "ar-SY": "dd/MM/yyyy",
            "ar-TN": "dd-MM-yyyy",
            "ar-YE": "dd/MM/yyyy",

            "arn": "dd-MM-yyyy",
            "arn-CL": "dd-MM-yyyy",

            "as": "dd-MM-yyyy",
            "as-IN": "dd-MM-yyyy",

            "az": "dd.MM.yyyy",
            "az-Cyrl-AZ": "dd.MM.yyyy",
            "az-Latn-AZ": "dd.MM.yyyy",

            "ba": "dd.MM.yy",
            "ba-RU": "dd.MM.yy",

            "be": "dd.MM.yyyy",
            "be-BY": "dd.MM.yyyy",

            "bg": "dd.M.yyyy",
            "bg-BG": "dd.M.yyyy",

            "bn": "dd-MM-yy",
            "bn-BD": "dd-MM-yy",
            "bn-IN": "dd-MM-yy",

            "bo": "yyyy/M/d",
            "bo-CN": "yyyy/M/d",

            "br": "dd/MM/yyyy",
            "br-FR": "dd/MM/yyyy",

            "bs": "d.M.yyyy",
            "bs-Cyrl-BA": "d.M.yyyy",
            "bs-Latn-BA": "d.M.yyyy",

            "ca": "dd/MM/yyyy",
            "ca-ES": "dd/MM/yyyy",

            "co": "dd/MM/yyyy",
            "co-FR": "dd/MM/yyyy",

            "cs": "d.M.yyyy",
            "cs-CZ": "d.M.yyyy",

            "cy": "dd/MM/yyyy",
            "cy-GB": "dd/MM/yyyy",

            "da": "dd-MM-yyyy",
            "da-DK": "dd-MM-yyyy",

            "de": "dd.MM.yyyy",
            "de-AT": "dd.MM.yyyy",
            "de-CH": "dd.MM.yyyy",
            "de-DE": "dd.MM.yyyy",
            "de-LI": "dd.MM.yyyy",
            "de-LU": "dd.MM.yyyy",

            "dv": "dd/MM/yy",
            "dv-MV": "dd/MM/yy",

            "dsb": "d. M. yyyy",
            "dsb-DE": "d. M. yyyy",

            "el": "d/M/yyyy",
            "el-GR": "d/M/yyyy",

            "en": "yyyy-MM-dd",
            "en-AU": "d/MM/yyyy",
            "en-BZ": "dd/MM/yyyy",
            "en-CA": "dd/MM/yyyy",
            "eu-ES": "yyyy/MM/dd",
            "eu-EN": "yyyy/MM/dd",
            "en-IE": "dd/MM/yyyy",
            "en-IN": "dd-MM-yyyy",
            "en-JM": "dd/MM/yyyy",
            "en-GB": "dd/MM/yyyy",
            "en-MY": "d/M/yyyy",
            "en-NZ": "d/MM/yyyy",
            "en-PH": "M/d/yyyy",
            "en-SG": "d/M/yyyy",
            "en-TT": "dd/MM/yyyy",
            "en-US": "M/d/yyyy",
            "en-ZA": "yyyy/MM/dd",
            "en-ZW": "M/d/yyyy",
            "en-029": "MM/dd/yyyy",

            "es": "dd/MM/yyyy",
            "es-AR": "dd/MM/yyyy",
            "es-BO": "dd/MM/yyyy",
            "es-CO": "dd/MM/yyyy",
            "es-CL": "dd-MM-yyyy",
            "es-CR": "dd/MM/yyyy",
            "es-DO": "dd/MM/yyyy",
            "es-EC": "dd/MM/yyyy",
            "es-ES": "dd/MM/yyyy",
            "es-GT": "dd/MM/yyyy",
            "es-HN": "dd/MM/yyyy",
            "es-MX": "dd/MM/yyyy",
            "es-NI": "dd/MM/yyyy",
            "es-PA": "MM/dd/yyyy",
            "es-PE": "dd/MM/yyyy",
            "es-PR": "dd/MM/yyyy",
            "es-PY": "dd/MM/yyyy",
            "es-SV": "dd/MM/yyyy",
            "es-US": "M/d/yyyy",
            "es-UY": "dd/MM/yyyy",
            "es-VE": "dd/MM/yyyy",

            "et": "d.MM.yyyy",
            "et-EE": "d.MM.yyyy",

            "eu": "yyyy/MM/dd",

            "fa": "MM/dd/yyyy",
            "fa-IR": "MM/dd/yyyy",

            "fi": "d.M.yyyy",
            "fi-FI": "d.M.yyyy",

            "fil": "M/d/yyyy",
            "fil-PH": "M/d/yyyy",

            "fo": "dd-MM-yyyy",
            "fo-FO": "dd-MM-yyyy",

            "fr": "DD/MM/yyyy",
            "fr-CA": "yyyy-MM-dd",
            "fr-CH": "dd.MM.yyyy",
            "fr-BE": "d/MM/yyyy",
            "fr-FR": "dd/MM/yyyy",
            "fr-LU": "dd/MM/yyyy",
            "fr-MC": "dd/MM/yyyy",
            "fr-CO": "dd/MM/yyyy",

            "fy": "d-M-yyyy",
            "fy-NL": "d-M-yyyy",

            "ga": "dd/MM/yyyy",
            "ga-IE": "dd/MM/yyyy",

            "gd": "dd/MM/yyyy",
            "gd-GB": "dd/MM/yyyy",

            "gl": "dd/MM/yy",
            "gl-ES": "dd/MM/yy",

            "gu": "dd-MM-yy",
            "gu-IN": "dd-MM-yy",

            "gsw": "dd/MM/yyyy",
            "gsw-FR": "dd/MM/yyyy",

            "ha": "d/M/yyyy",
            "ha-Latn-NG": "d/M/yyyy",

            "he": "dd/MM/yyyy",
            "he-IL": "dd/MM/yyyy",

            "hi": "dd-MM-yyyy",
            "hi-IN": "dd-MM-yyyy",

            "hr": "d.M.yyyy",
            "hr-BA": "d.M.yyyy.",
            "hr-HR": "d.M.yyyy",

            "hsb": "d. M. yyyy",
            "hsb-DE": "d. M. yyyy",

            "hu": "yyyy. MM. dd.",
            "hu-HU": "yyyy. MM. dd.",

            "hy": "dd.MM.yyyy",
            "hy-AM": "dd.MM.yyyy",

            "id": "dd/MM/yyyy",
            "id-ID": "dd/MM/yyyy",

            "ig": "d/M/yyyy",
            "ig-NG": "d/M/yyyy",

            "ii": "yyyy/M/d",
            "ii-CN": "yyyy/M/d",

            "is": "d.M.yyyy",
            "is-IS": "d.M.yyyy",

            "it": "dd/MM/yyyy",
            "it-CH": "dd.MM.yyyy",
            "it-IT": "dd/MM/yyyy",

            "iu": "d/M/yyyy",
            "iu-Cans-CA": "d/M/yyyy",
            "iu-Latn-CA": "d/MM/yyyy",

            "ja": "yyyy/MM/dd",
            "ja-JP": "yyyy/MM/dd",

            "ka": "dd.MM.yyyy",
            "ka-GE": "dd.MM.yyyy",

            "kk": "dd.MM.yyyy",
            "kk-KZ": "dd.MM.yyyy",

            "kl": "dd-MM-yyyy",
            "kl-GL": "dd-MM-yyyy",

            "km": "yyyy-MM-dd",
            "km-KH": "yyyy-MM-dd",

            "kn": "dd-MM-yy",
            "kn-IN": "dd-MM-yy",

            "ko": "yyyy-MM-dd",
            "ko-KR": "yyyy-MM-dd",

            "kok": "dd-MM-yyyy",
            "kok-IN": "dd-MM-yyyy",

            "ky": "dd.MM.yy",
            "ky-KG": "dd.MM.yy",

            "lb": "dd/MM/yyyy",
            "lb-LU": "dd/MM/yyyy",

            "lo": "dd/MM/yyyy",
            "lo-LA": "dd/MM/yyyy",

            "lt": "yyyy.MM.dd",
            "lt-LT": "yyyy.MM.dd",

            "lv": "yyyy.MM.dd.",
            "lv-LV": "yyyy.MM.dd.",

            "mi": "dd/MM/yyyy",
            "mi-NZ": "dd/MM/yyyy",

            "mk": "dd.MM.yyyy",
            "mk-MK": "dd.MM.yyyy",

            "ml": "dd-MM-yy",
            "ml-IN": "dd-MM-yy",

            "mn": "yy.MM.dd",
            "mn-MN": "yy.MM.dd",
            "mn-Mong-CN": "yyyy/M/d",

            "moh": "M/d/yyyy",
            "moh-CA": "M/d/yyyy",

            "mr": "dd-MM-yyyy",
            "mr-IN": "dd-MM-yyyy",

            "ms": "dd/MM/yyyy",
            "ms-BN": "dd/MM/yyyy",
            "ms-MY": "dd/MM/yyyy",

            "mt": "dd/MM/yyyy",
            "mt-MT": "dd/MM/yyyy",

            "nb": "dd.MM.yyyy",
            "nb-NO": "dd.MM.yyyy",

            "ne": "M/d/yyyy",
            "ne-NP": "M/d/yyyy",

            "nl": "d-M-yyyy",
            "nl-BE": "d/MM/yyyy",
            "nl-NL": "d-M-yyyy",

            "nn": "dd.MM.yyyy",
            "nn-NO": "dd.MM.yyyy",

            "nso": "yyyy/MM/dd",
            "nso-ZA": "yyyy/MM/dd",

            "oc": "dd/MM/yyyy",
            "oc-FR": "dd/MM/yyyy",

            "or": "dd-MM-yy",
            "or-IN": "dd-MM-yy",

            "pa": "dd-MM-yy",
            "pa-IN": "dd-MM-yy",

            "pl": "yyyy-MM-dd",
            "pl-PL": "yyyy-MM-dd",

            "prs": "dd/MM/yy",
            "prs-AF": "dd/MM/yy",

            "ps": "dd/MM/yy",
            "ps-AF": "dd/MM/yy",

            "pt": "d/M/yyyy",
            "pt-BR": "d/M/yyyy",
            "pt-PT": "dd-MM-yyyy",

            "qut": "dd/MM/yyyy",
            "qut-GT": "dd/MM/yyyy",

            "quz": "dd/MM/yyyy",
            "quz-BO": "dd/MM/yyyy",
            "quz-EC": "dd/MM/yyyy",
            "quz-PE": "dd/MM/yyyy",

            "rm": "dd/MM/yyyy",
            "rm-CH": "dd/MM/yyyy",

            "ro": "dd.MM.yyyy",
            "ro-RO": "dd.MM.yyyy",

            "ru": "dd.MM.yyyy",
            "ru-RU": "dd.MM.yyyy",

            "rw": "M/d/yyyy",
            "rw-RW": "M/d/yyyy",

            "sa": "dd-MM-yyyy",
            "sa-IN": "dd-MM-yyyy",

            "sah": "MM.dd.yyyy",
            "sah-RU": "MM.dd.yyyy",

            "se": "d.M.yyyy",
            "se-FI": "d.M.yyyy",
            "se-NO": "dd.MM.yyyy",
            "se-SE": "yyyy-MM-dd",

            "si": "yyyy-MM-dd",
            "si-LK": "yyyy-MM-dd",

            "sk": "d. M. yyyy",
            "sk-SK": "d. M. yyyy",

            "sl": "d.M.yyyy",
            "sl-SI": "d.M.yyyy",

            "sma": "dd.MM.yyyy",
            "sma-NO": "dd.MM.yyyy",
            "sma-SE": "yyyy-MM-dd",

            "smj": "dd.MM.yyyy",
            "smj-NO": "dd.MM.yyyy",
            "smj-SE": "yyyy-MM-dd",

            "smn": "d.M.yyyy",
            "smn-FI": "d.M.yyyy",

            "sms": "d.M.yyyy",
            "sms-FI": "d.M.yyyy",

            "sq": "yyyy-MM-dd",
            "sq-AL": "yyyy-MM-dd",

            "sr": "d.M.yyyy",
            "sr-Cyrl-BA": "d.M.yyyy",
            "sr-Cyrl-CS": "d.M.yyyy",
            "sr-Cyrl-ME": "d.M.yyyy",
            "sr-Cyrl-RS": "d.M.yyyy",
            "sr-Latn-BA": "d.M.yyyy",
            "sr-Latn-CS": "d.M.yyyy",
            "sr-Latn-ME": "d.M.yyyy",
            "sr-Latn-RS": "d.M.yyyy",

            "sv": "yyyy-MM-dd",
            "sv-FI": "d.M.yyyy",
            "sv-SE": "yyyy-MM-dd",

            "sw": "M/d/yyyy",
            "sw-KE": "M/d/yyyy",

            "syr": "dd/MM/yyyy",
            "syr-SY": "dd/MM/yyyy",

            "ta": "dd-MM-yyyy",
            "ta-IN": "dd-MM-yyyy",

            "te": "dd-MM-yy",
            "te-IN": "dd-MM-yy",

            "tg": "dd.MM.yy",
            "tg-Cyrl-TJ": "dd.MM.yy",

            "th": "d/M/yyyy",
            "th-TH": "d/M/yyyy",

            "tk": "dd.MM.yy",
            "tk-TM": "dd.MM.yy",

            "tn": "yyyy/MM/dd",
            "tn-ZA": "yyyy/MM/dd",

            "tr": "dd.MM.yyyy",
            "tr-TR": "dd.MM.yyyy",

            "tt": "dd.MM.yyyy",
            "tt-RU": "dd.MM.yyyy",

            "tzm": "dd-MM-yyyy",
            "tzm-Latn-DZ": "dd-MM-yyyy",

            "ug": "yyyy-M-d",
            "ug-CN": "yyyy-M-d",

            "uk": "dd.MM.yyyy",
            "uk-UA": "dd.MM.yyyy",

            "ur": "dd/MM/yyyy",
            "ur-PK": "dd/MM/yyyy",

            "uz": "dd/MM yyyy",
            "uz-Cyrl-UZ": "dd.MM.yyyy",
            "uz-Latn-UZ": "dd/MM yyyy",

            "vi": "dd/MM/yyyy",
            "vi-VN": "dd/MM/yyyy",

            "wo": "dd/MM/yyyy",
            "wo-SN": "dd/MM/yyyy",

            "xh": "yyyy/MM/dd",
            "xh-ZA": "yyyy/MM/dd",

            "yo": "d/M/yyyy",
            "yo-NG": "d/M/yyyy",

            "zh": "yyyy/M/d",
            "zh-CN": "yyyy/M/d",
            "zh-HK": "d/M/yyyy",
            "zh-MO": "d/M/yyyy",
            "zh-TW": "yyyy/M/d",
            "zh-SG": "d/M/yyyy",

            "zu": "yyyy/MM/dd",
            "zu-ZA": "yyyy/MM/dd",
        };

        let found = formats[locale]

        if (!found) {
            locale = navigator.language.split("-")[0] || 'en'
            found = formats[locale]
        }

        return  found || 'dd/MM/yyyy';

    }
}