export default {
  isMasterBase() {
    return [
      'callcenter.planete-online.fr',
      // "emansart.planett.net",
      'hotline.planete-online.fr',
      'lean-gtp.planett.net',
      'lean-dev.planett.net',
      // 'microservice.planett.net',
    ].includes(this.getDns());
  },
  getModuleName(module = '', sous_module = '', list_modules = []) {
    let ret = {
      module: module,
      sous_module: sous_module,
    };

    if (sous_module && list_modules && list_modules.length > 0) {
      let subModulePathToTest =
        sous_module.path[0] == '.'
          ? sous_module.path.substr(1)
          : sous_module.path;
      subModulePathToTest =
        sous_module.path[0] == '/'
          ? sous_module.path.substr(1)
          : sous_module.path;

      let subModuleFound = null;

      let moduleFound = list_modules.find((module) => {
        return module.subModules.some((subModule) => {
          let subModulePath =
            subModule.path[0] == '.'
              ? subModule.path.substr(1)
              : subModule.path;
          subModulePath =
            subModule.path[0] == '/'
              ? subModule.path.substr(1)
              : subModule.path;

          if (subModulePath == subModulePathToTest) {
            subModuleFound = subModule;
            return true;
          } else return false;
        });
      });

      if (subModuleFound) {
        ret.module = moduleFound;
        ret.sous_module = subModuleFound;
      } else {
        ret.module = module;
        ret.subModule = sous_module;
      }
    } else { 
      let moduleFound = list_modules.find((m) => {
        return module.name === m.nameTraduction;
      });
      if ( moduleFound!==undefined ) {
        ret.module = moduleFound;
      }
      else {
        ret.module = module

      }
      ret.sous_module = sous_module;
    }

    if (ret.module === null) {
      ret.module = { name: '' };
    }
    if (ret.sous_module === null) {
      ret.sous_module = { name: '' };
    }
    return ret;
  },
  getColorEtat(id) {
    if (id == 3) {
      return 'error';
    }
    if ([1, 2].includes(id)) {
      return 'warning';
    }
    if ([4, 5].includes(id)) {
      return 'success';
    }
  },
  getTypeColorSingle(id) {
    let mappedColors = { 1: 'error', 2: 'cyan', 3: 'indigo', 4: 'success' };
    return mappedColors[id];
  },
  getPrioriteColorSingle(id) {
    let mappedColors = { 1: 'teal', 2: 'teal', 3: 'error' };
    return mappedColors[id];
  },
  getImpactColorSingle(id) {
    let mappedColors = { 1: 'teal', 2: 'teal', 3: 'teal', 4: 'error' };
    return mappedColors[id];
  },
};
