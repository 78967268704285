import UserService from "@/Services/UserService"
import CryptoJS from "crypto-js"
import Vue from 'vue'
import VueRouter from 'vue-router'
import LoginService from "../Services/LoginService"
import Store from "../Store/index"

import SupportVisionRouting from "@/Router/support-vision"

Vue.use(VueRouter)

const routes = [
    {
        path: '/app',
        name: 'App',
        meta: {
            requiresAuth: true,
        },
        component: () => import("@/Components/Views/Main"),
        children: [
            SupportVisionRouting,
            {
                path: 'reset-password',
                name: 'ResetPassword',
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Login/ResetPassword")
            },
            {
                path: "home",
                name: "Home",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Home/Index")
            },
            {
                path: "modules",
                name: "Modules",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Modules/Index")
            },
            {
                path: "agenda",
                name: "Agenda",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Agenda/Index")
            },
            {
                path: "planete-online-news/:id?",
                name: "PlaneteOnlineNews",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/News/Index")
            },
            {
                path: "my-account",
                name: "MyAccount",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/MyAccount/Index")
            },
            {
                path: "logout",
                name: "Logout",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Home/Logout")
            },
            {
                path: "login",
                name: "AppLogin",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Login/Index")
            },
            {
                path: "/update-notes",
                name: "UpdateNotes",
                meta: {
                    requiresAuth: true
                },
                component: () => import("@/Components/Views/UpdateNotes/Index")
            },
            {
                path: "about",
                name: "About",
                meta: {
                    requiresAuth: true
                },
                component: () => import("@/Components/Views/About/Index")
            },
            {
                path: "hotline",
                name: "Hotline",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Hotline/Hotline")
            },
            {
                path: "hotline-rights",
                name: "HotlineRights",
                meta: {
                    requiresAuth: true,
                },
                component: () => import("@/Components/Views/Hotline/HotlineRights")
            },
            {
                path: "*",
                name: "NotFoundConnectedApp",
                meta: {
                    requiresAuth: true
                },
                component: () => import("@/Components/Views/_default/NotFoundConnected")
            },
        ]
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            requiresAuth: false,
        },
        component: () => import("@/Components/Views/Login/Index"),
    },
    {
        path: '/forgotten-password',
        name: 'ForgottenPassword',
        meta: {
            requiresAuth: false,
        },
        component: () => import("@/Components/Views/Login/ResetPassword"),
    },
    {
        path: "/password-recovery",
        name: "PasswordRecovery",
        meta: {
            requiresAuth: false
        },
        component: () => import("@/Components/Views/Login/ResetPassword")
    },
    {
        path: "/edit-password",
        name: "EditPassword",
        meta: {
            requiresAuth: false,
        },
        component: () => import("@/Components/Views/Login/EditPassword")
    },
    {
        path: "*",
        name: "NotFoundConnected",
        meta: {
            requiresAuth: true
        },
        component: () => import("@/Components/Views/_default/NotFoundConnected")
    },
    {
        path: "*",
        name: "NotFoundNotConnected",
        meta: {
            requiresAuth: false
        },
        component: () => import("@/Components/Views/_default/NotFoundNotConnected")
    }
]


const router = new VueRouter({
    mode: "history",
    routes
})

router.beforeEach(async (to, from, next) => {

    let isAuthenticated = await LoginService.isConnected(localStorage.getItem(CryptoJS.MD5("userToken")))
    if (!isAuthenticated && localStorage.getItem(CryptoJS.MD5("userToken")) != undefined) {
        localStorage.removeItem(CryptoJS.MD5("userToken"))
    }
    
    
    if (to.matched.some((record) => record.meta.requiresAuth)) {
        if (isAuthenticated) {
            
            const user = await UserService.getUser()
            // // ----------------- AD CONFIGS SSO -----------------
            // const adConfigs = await LoginService.getADconfigs()
            // const userMatchAdSuperAdmin = Array.isArray(adConfigs) && adConfigs.some(adConfig => adConfig.AD_config.DroitSupportVision === "SUPERADMIN" && adConfig.AD_config.user.id === user.id)
            // -------------------------------------------------
            Store.commit("setUser", {user: user})
            let redirections = ["/", "/app", "/home"]
            if (redirections.indexOf(to.path) !== -1) {
                next({name: "Home"})
            } 
            // else if (to.path == "/app/support-vision/droits" && !userMatchAdSuperAdmin) { // Si l'utilisateur n'est pas superadmin ou pas connecté depuis SSO on le redirige vers la page d'accueil
            //     next({name: "Home"})
            // } 
            else {
                next()
            }
            return
        }
        next({name: "Login"})
    } else {
        if ((to.name == "Login" || to.path == "/") && isAuthenticated) {
            next({name: "Home"})
        } else {
            if (to.path == "/") {
                next({name: "Login"})
            } else {
                next()
            }
        }
    }
//
})


export default router
