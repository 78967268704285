import Vue from "vue"

import MixinNotification from "./Notify"
import MixinMessagerie from "./Messagerie"
import MixinWidget from "./Widgets"
import MixinUtils from "./Utils"
import MixinUnits from "../Utils/Units"
import MixinLang from "./Lang"

Vue.mixin(MixinNotification)
Vue.mixin(MixinMessagerie)
Vue.mixin(MixinWidget)
Vue.mixin(MixinUtils)
Vue.mixin(MixinUnits)
Vue.mixin(MixinLang)