export default {
    getPrioriteColor() {
        let mappedColors = { 1: "teal", 2: "teal", 3: "error" };
        return mappedColors[this.fiche.fiche.ordre_priorite.value.id];
    },
    getImpactColor() {
        let mappedColors = { 1: "teal", 2: "teal", 3: "teal", 4: "error" };
        return mappedColors[this.fiche.fiche.impact.value.id];
    },
    getTypeColor(id) {
        let mappedColors = { 1: "error", 2: "cyan", 3: "indigo", 4: "success" };
        return mappedColors[id];
    },
}