import ApiPromise from "@/Services/ApiPromise"

export default class Request {

    url = ""
    method = ""
    data = {}

    constructor(url, method, data) {
        this.url = url
        this.method = method
        this.data = data
    }

    async onRelease(handler) {
        this.handlerOnRelease = handler
        return this
    }

    onFail(handler) {
        this.handlerOnFail = handler
        return this
    }

    onComplete(handler) {
        this.handlerOnComplete = handler
        return this
    }

    async send() {
        let $this = this
        await ApiPromise()[this.method](this.url, this.data)
            .then(response => {
                if ($this.handlerOnRelease) {
                    $this.handlerOnRelease(response)
                }
            })
            .catch(error => {
                if ($this.handlerOnFail) {
                    $this.handlerOnFail(error)
                }
            })
            .finally(() => {
            if ($this.handlerOnComplete)
                $this.handlerOnComplete()
            })
    }


}