import colors from 'vuetify/lib/util/colors'

export default {
    getTextColor(colorName, theme) {
        return colors[colorName]
            ? colors[colorName].base
            : theme[colorName]
                ? theme[colorName]
                : colorName
    },
    primary: {
        base: "#7b4e8e",
        lighten1: "#9361a8",
        lighten2: "#a87fb8",
        lighten3: "#bc9dc8",
        lighten4: "#d0bad9",
        lighten5: "#e4d8e9",
        darken1: "#6c457d",
        darken2: "#5e3d6c",
        darken3: "#50335c",
        darken4: "#432a4c",
        accent1: "#7f4398",
        accent2: "#8338a3",
        accent3: "#882dae",
        accent4: "#8c22b9"
    },
    secondary: {
        base: "#3A67A6",
        lighten1: "#4e7ec1",
        lighten2: "#7096cd",
        lighten3: "#92afd9",
        lighten4: "#b4c8e4",
        lighten5: "#d6e1f0",
        darken1: "#345b93",
        darken2: "#2d5080",
        darken3: "#26446d",
        darken4: "#20385b",
        accent1: "#2f65b1",
        accent2: "#2463bc",
        accent3: "#1962c8",
        accent4: "#0d60d3"
    },
    tertiary: {
        base: "#CA3975",
        lighten1: "#d45e8f",
        lighten2: "#de82a8",
        lighten3: "#e7a6c1",
        lighten4: "#f1cbdb",
        lighten5: "#fbeff4",
        darken1: "#b9316a",
        darken2: "#a52c5e",
        darken3: "#912753",
        darken4: "#7d2147",
        accent1: "#d53074",
        accent2: "#df2673",
        accent3: "#e91c71",
        accent4: "#f3126f"
    }

}