import Api from '@/Services/Api'
import Request from "@/Services/FetchOrchestrator/Request";
import FetchOrchestrator from "@/Services/FetchOrchestrator/FetchOrchestrator";

export default {
    async getInterface() {
        let response = await Api().get('/interface')
        return response.data
    },
    async postInterface(datas) {

        //Retire l'image de profil car trop lourd
        if (datas.informations) {
            datas = JSON.parse(JSON.stringify(datas))
            datas.informations.photo = ""
        }

        // Retire l'image de fond car trop lourd
        if (datas.preferences) {
            if (datas.preferences.background.type != "predefined") {
                datas = JSON.parse(JSON.stringify(datas))
                datas.preferences.background = ""
            }
        }

        let response = await Api().post('/interface', datas)
        return response.data
    },

    async getFicheLiaison() {
        let request = new Request("/planete-online/fiche-liaison", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data)
            })
            request.onFail(reject)
        });
    },
    async getRightsFicheLiaison() {
        let request = new Request("/planete-online/fiche-liaison/rights", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : response.data.rights || 0)
            })
            request.onFail(reject)
        });
    },
    async checkVideos() {
        let request = new Request("/planete-online/alertes/video", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : {hasNewVideo: response.data.data > 0})
            })
            request.onFail(reject)
        });
    },
    async getVideos() {
        let request = new Request("/Interface/videos.php", "get")
        await FetchOrchestrator.fetch(request)

        return new Promise((resolve, reject) => {
            request.onRelease(function (response) {
                resolve((!response || response.status == 404) ? 404 : {hasNewVideo: response.data.data > 0})
            })
            request.onFail(reject)
        });
    },
    async getBases(sortedByClient = false) {
        let response = await Api().get("/planete-online/bases")
        if (sortedByClient) {
            let bases = response.data
            bases.forEach(a => {
                let children = a.bases.map(b => {return {name: b}} )
                a.children = children
            })
            bases = bases.filter(a => a.name)
            bases.sort((a, b) => a.name.localeCompare(b.name))
            return bases
        } else {
            return response.data
        }
    },
    async getBases2() {
        let response = await Api().get("/planete-online/bases2")
        return response.data
    },
    async getBases3() {
        let response = await Api().get("/planete-online/bases3")
        return response.data
    },
    async getGuide() {
        let response = await Api().get("/upload/guide", {responseType: "blob"})
            .then(response => {
                let fileURL = URL.createObjectURL(response.data);
                window.open(fileURL);
            }).catch(console.error)
    },

    async getHotlineGuide() {
        let response = await Api().get("/upload/guide-hotline", {responseType: "blob"})
            .then(response => {
                let fileURL = URL.createObjectURL(response.data);
                window.open(fileURL);
            }).catch(console.error)
    }

}
