const Notification = window.Notification || window.webkitNotification || undefined

// Plugin
const VueNativeNotification = {
  install: function (Vue, options) {

    if (Notification) {
      options = options || {}
      options.requestOnNotify = options.requestOnNotify || true

      Vue.notification = {}
      Vue.prototype.$notification = {}

      // Manual permission request
      var requestPermission = function () {
        return Notification.requestPermission()
      }
      Vue.notification.requestPermission = requestPermission
      Vue.prototype.$notification.requestPermission = requestPermission

      // Show function
      var show = function (title, opts, e) {
        if (!e) {
          e = {}
        }
        if (!e.onerror) e.onerror = () => {}
        if (!e.onclick) e.onclick = () => {}
        if (!e.onclose) e.onclose = () => {}
        if (!e.onshow) e.onshow = () => {}

        return Promise.resolve()
            .then(function () {
              if (options.requestOnNotify && Notification.permission !== 'granted') {
                return requestPermission()
              }

              return Notification.permission
            })
            .then(function (permission) {
              // "default" doesn't mean "denied"
              // It means the user has dismissed the request
              if (permission === 'denied') {
                return new Error('No permission to show notification')
              }

              const bindOnError = function (event) {
                'use strict'
                e.onerror(event)
              }

              const bindOnClick = function (event) {
                'use strict'
                e.onclick(event)
              }

              const bindOnClose = function (event) {
                'use strict'
                e.onclose(event)
              }

              const bindOnShow = function (event) {
                'use strict'
                e.onshow(event)
              }

              // Create Notification object
              try {
                const notification = new Notification(title, opts)

                notification.onerror = bindOnError
                notification.onclick = bindOnClick
                notification.onclose = bindOnClose
                notification.onshow = bindOnShow

                return notification
              } catch (e) {
                if (e.name !== 'TypeError') {
                  return e
                }

                return navigator.serviceWorker.ready.then(
                    function (reg) {
                      reg.showNotification(title, opts)
                    }).then(bindOnShow, bindOnError)
              }
            })
      }
      Vue.notification.show = show
      Vue.prototype.$notification.show = show
    }
  }
}

// Automatic installation
if (typeof window !== 'undefined' && window.Vue) {
  window.Vue.use(VueNativeNotification)
}

// Export plugin
export default VueNativeNotification
