export default {

    queue: [],
    pending: false,

    async fetch(request) {

        if (request) {
            if (!this.reqExists(request)) {
                this.queue.push(request)
            }
        }

        if (!this.pending) {

            let req = this.queue.shift()
            this.pending = true
            req.send()
                .finally(() => {
                    this.pending = false

                    if (this.queue.length > 0) {
                        this.fetch()
                    }
                })
        }

    },

    reqExists(request) {
        return this.queue.filter(req => {
            return req.url == request.url
        }).length > 0
    }

}