import TraductionService from "@/Services/TraductionService"
import UserService from "@/Services/UserService"
import moment from "moment"
import PlaneteOnlineService from "@/Services/PlaneteOnlineService"

export default {
    methods: {
        changeLang(lang, authenticated = true) {

            lang = lang.toLowerCase()
            let $this = this

            // Lance le loader sur le chargement de la langue
            this.$store.commit("loadingLanguage", true)

            // Récupère les traductions du service de traduction
            TraductionService
                .get(lang)
                .then((messages) => {

                    // Set de la locale sur différents modules et librairies
                    $this.$i18n.setLocaleMessage(lang, messages["interface"])
                    $this.$i18n.locale = lang == 'ca' ? 'en-ca' : lang
                    $this.$store.commit("translate", lang)

                    // Spécifique pour la lib tiptap qui ne comprend pas le code langue "pt" mais uniquement "ptbr"
                    let tiptapLocale = lang == "pt" ? "ptbr" : lang
                    $this.$vuetify.lang.current = tiptapLocale

                    // Set de la langue sur l'objet window pour partager aux autres applis
                    window.__langue = lang

                    if (authenticated) {
                        // Récupération des modules en fonction de la langue
                        UserService
                            .getModules(lang)
                            .then((moduleResponse) => {
                                if (moduleResponse) {
                                    let modules = Object.values(moduleResponse)
                                    modules.forEach(module => {
                                        module.slug = $this.slugify($this.htmlDecode(module.name))
                                        module.subModules.forEach(subModule => {
                                            subModule.slug = $this.slugify($this.htmlDecode(subModule.name))
                                        })
                                    })
                                    $this.$store.commit("resetStore", {modules})
                                }
                            })
                            .finally(() => {

                                PlaneteOnlineService
                                    .postInterface(this.$_.pick(this.$store.state, ["preferences"]))
                                    .finally(() => {
                                        // Stop le loader du chargement de langue
                                        $this.$store.commit("loadingLanguage", false)
                                    })

                            })
                    }
                })

        },
        /**
         * Converti un timestamp en date lisible
         * @var time le timestamp
         */
        timestampFormat(time, format = null) {
            return moment(time * 1000).locale(this.$i18n.locale).format(format || "LLL"); // Todo : config globale format date par défaut
        },
        /**
         * Converti une date JS en date lisible
         * @var time la date JS
         */
        formatDate(date, format = null) {
            return moment(date).locale(this.$i18n.locale).format(format || "LLL");
        },
    }
}