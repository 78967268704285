import axios from "axios"

export const Api = () => {
  let instance = axios.create({
    baseURL: process.env.VUE_APP_BASEURL
  })

  instance.defaults.headers.post["Content-Type"] = process.env.VUE_APP_DEFAULT_CONTENT_TYPE
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = document.location.origin
  instance.defaults.withCredentials = true

  if (sessionStorage.getItem("AUTH_TOKEN")) {
    instance.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("AUTH_TOKEN")
  }

  let api = {
    get: async (url, config) => {
      try {
        return await instance.get(url, config)
      } catch(e) {
        console.error("Error while fetching " + " GET " + url)
        if (e.response && e.response.data) {
          return e.response.data
        } else {
          return null
        }
      }
    },
    delete: async (url, config) => {
      try {
        return await instance.delete(url, config)
      } catch(e) {
        console.error("Error while fetching " + " DELETE " + url)
        if (e.response && e.response.data) {
          return e.response.data
        } else {
          return null
        }
      }
    },
    head: async (url, config) => {
      try {
        return await instance.head(url, config)
      } catch(e) {
        console.error("Error while fetching " + " HEAD " + url)
        if (e.response && e.response.data) {
          return e.response.data
        } else {
          return null
        }
      }
    },
    options: async (url, config) => {
      try {
        return await instance.options(url, config)
      } catch(e) {
        console.error("Error while fetching " + " OPTIONS " + url)
        if (e.response && e.response.data) {
          return e.response.data
        } else {
          return null
        }
      }
    },
    post: async (url, data, config) => {
      try {
        return await instance.post(url, data, config)
      } catch(e) {
        console.error("Error while fetching " + " POST " + url)
        if (e.response && e.response.data) {
          return e.response.data
        } else {
          return null
        }
      }
    },
    put: async (url, data, config) => {
      try {
        return await instance.put(url, data, config)
      } catch(e) {
        console.error("Error while fetching " + " PUT " + url)
        if (e.response && e.response.data) {
          return e.response.data
        } else {
          return null
        }
      }
    },
    patch: async (url, data, config) => {
      try {
        return await instance.patch(url, data, config)
      } catch(e) {
        console.error("Error while fetching " + " PATCH " + url)
        if (e.response && e.response.data) {
          return e.response.data
        } else {
          return null
        }
      }
    }
  }

  return api
}

export default Api
