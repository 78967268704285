import Api from '@/Services/Api'

export default {
    async getUser() {
        let response = await Api().get('/user')
        return response.data
    },
    async postUser(datas) {
        let response = await Api().post('/user', datas)
        if (response.status == 200) {
            if (response.headers["content-type"].includes("application/json")) {
                return response.data
            }
        } else {
            if (response == "changingPasswordNeedAnotherPassword") {
                return "changingPasswordNeedAnotherPassword"
            } else {
                return {error: response.data}
            }
        }
    },
    async getSignature() {
        let response = await Api().get('/user/signature')
        return response.data
    },
    async postSignature(datas) {
        let response = await Api().post('/user/signature', datas)
        return response.status == 200
    },
    async deleteSignature() {
        let response = await Api().delete('/user/signature')
        return response.status == 200
    },
    async postPasswordSignature(data) {
        let response = await Api().post("/user/savePasswordSignature", data)
        return response.status == 200
    },
    async postPhoto(datas) {
        let response = await Api().post('/upload/photos', datas)
        return response.data
    },
    async getPhoto() {
        let response = await Api().get('/upload/photos')
        return response.data
    },
    async deletePhoto() {
        let response = await Api().delete("/upload/photos")
        return response.status == 200
    },
    async postWallpaper(datas) {
        let response = await Api().post('/upload/wallpapers', datas)
        return response.data
    },
    async getWallpaper() {
        let response = await Api().get('/upload/wallpapers')
        return response.data
    },
    async deleteWallpaper() {
        let response = await Api().delete("/upload/wallpapers")
        return response.status == 200
    },
    async getModules(langue) {
        let response = await Api().get('/user/modules/' + langue)
        return response.data
    },
    async getUsers(){
        let response = await Api().get('/user/users')
        return response.data
    }
}
