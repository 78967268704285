import axios from "axios"
import CryptoJS from "crypto-js"

export const Api = () => {

  let instance = axios.create({
    baseURL: process.env.VUE_APP_BASEURL
  })

  instance.defaults.headers.post["Content-Type"] = process.env.VUE_APP_DEFAULT_CONTENT_TYPE
  instance.defaults.headers.common["Access-Control-Allow-Origin"] = document.location.origin
  instance.defaults.withCredentials = true

  if (sessionStorage.getItem("AUTH_TOKEN")) {
    instance.defaults.headers.common["Authorization"] = "Bearer " + sessionStorage.getItem("AUTH_TOKEN")
  }

  return instance

}

export default Api
