import Vue from "vue"
import vuescroll from "vuescroll"
import PlaneteOnlineColors from "@/Utils/Colors"

Vue.use(vuescroll, {
    ops: {
        bar: {
            background: PlaneteOnlineColors.primary.base,
        },
        scrollPanel: {
            initialScrollX: false,
            scrollingX: false,
            easing: "easeInQuad"
        },
        rail: {
            background: PlaneteOnlineColors.primary.base,
            keepShow: false,
            opacity: .2,
            gutterOfEnds: "6px",
            gutterOfSide: "6px"
        },
    }
})