<template>
  <div>

    <template v-if="!loading">

      <template v-if="showTotal">
        Éléments sélectionnés : {{ total }}
      </template>

      <div v-for="(item, i) in items_" :key="i+'toto'">

        <v-row class="mt-0 pa-0">
          <v-col cols="2" class="py-2">
            <v-btn icon v-if="item.children" @click="expand(i)">
              <v-icon>{{ item.expanded ? "mdi-chevron-down" : "mdi-chevron-right" }}</v-icon>
            </v-btn>
          </v-col>

          <v-col class="d-flex flex-row align-center">
            <span class="mr-2 primary-text">{{ item.name }}</span>
            <template v-if="showSubtotal">({{ item.children.filter(child => child.selected).length }})</template>
          </v-col>

          <v-col cols="2">
            <v-checkbox v-model="item.selected" @change="toggle(item, i)" dense hide-details class="ma-0"></v-checkbox>
          </v-col>

        </v-row>
        <v-expand-transition>
          <div v-if="item.expanded">
            <div v-for="(child, j) in item.children" :key="j+'_'+i">
              <v-row class="mt-0">

                <v-col cols="2">
                </v-col>

                <v-col class="d-flex flex-row align-center">
                  <span :class="{'primary--text': child.selected}">{{ child.name }}</span>
                </v-col>

                <v-col cols="2">
                  <v-checkbox v-model="child.selected" @change="toggleChild(child, item)" dense hide-details class="ma-0"></v-checkbox>
                </v-col>

              </v-row>
            </div>
          </div>
        </v-expand-transition>
      </div>
    </template>
    <template v-if="loading">
      <v-container fluid class="text-center">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </v-container>
    </template>
  </div>
</template>

<script>
export default {
  name: "VTree",
  props: {
    list: [Array, Object],
    loading: Boolean,
    showTotal: Boolean,
    showSubtotal: Boolean
  },
  computed: {
    total() {
      return this.items_.map(parent => parent.children.filter(child => child.selected).length).reduce((acc, v) => acc + v, 0)
    }
  },
  data() {
    return {
      items_: this.list,
      initialized: false
    }
  },
  methods: {
    expand(index) {
      this.list[index].expanded = !this.list[index].expanded
      this.$emit("toggle-expand", index)
    },
    toggle(item, index) {
        let parent = this.items_[index]
            parent.children.forEach(a => a.selected = item.selected)
        this.$set(this.items_, index, parent)
        this.$emit(item.selected ? "add" : "remove", parent.children)
    },
    toggleChild(item, parent) {
      parent.selected = parent.children.every(a => a.selected)
      if (item.selected) {
        this.$emit("add", [item])
      } else {
        this.$emit("remove", [item])
      }
    },
    initList(list) {

      // Ajoute les propriétés expanded aux parents
      list.forEach(a => {
        if (a.children) {
          if (a.expanded === undefined)
            this.$set(a, "expanded", false)
          a.children.forEach(child => {
            if (!child.selected)
              this.$set(child, "selected", false)
          })
        }
        if (!a.selected)
          this.$set(a, "selected", false)
        a.selected = a.children.filter(child => child.selected).length == a.children.length
      })
      return list
    }
  },
  watch: {
    list(nV, oV) {
      if (nV) {
        this.items_ = this.initList(nV)
        this.initialized = true
        this.$emit("input", this.items_)
      }
    },
  },
}
</script>

<style scoped>

</style>