<template>
  <div class="d-none">
    <!-- Instancie un tag audio pour chaque son en cours de lecture (Le supprime du DOM une fois le son terminé) -->
    <audio
        v-for="(sound, i) in sounds" :key="i"
        :src="sound.obj" :ref="ref(sound.name)"
    ></audio>
  </div>
</template>

<script>
export default {
  name: "SoundPlayer",
  computed: {
    playingSounds() {
      return this.$uiSounds.state.actionsList
    },
    sounds() {
      return Object.keys(this.sounds_).map(a => {
        return {
          name: a,
          obj: this.sounds_[a]
        }
      })
    },
    preferences() {
      return this.$uiSounds.state.preferences
    }
  },
  data() {
    return {
      sounds_: this.$uiSounds.state.sounds
    }
  },
  methods: {
    ref: function (name) {
      return "planeteOnlineSound_" + name[0].toUpperCase() + name.substr(1)
    },
    getRef: function (name) {
      return this.$refs[this.ref(name)]
    },
    play: function (name) {
      let elem = this.getRef(name)
      if (elem != undefined) {
        elem = elem[0]
        if (this.preferences.soundOn) {
          elem.load()
          elem.play()
        }

        this.$uiSounds.state.actionsList.splice(this.$uiSounds.state.actionsList.find(a=>a==name), 1)
      }
    },
    stop: function (name) {
      let elem = this.getRef(name)
      if (elem != undefined) {
        elem.pause();
        elem.currentTime = 0;
      }
    },
  },
  watch: {
    playingSounds: {
      deep: true,
      handler() {
        for (let sound of this.playingSounds) {
          let split = sound.split(":")
          sound = split[0]
          let action = split[1]
          if (action == "play") {
            this.play(sound)
          } else if (action == "stop") {
            this.stop(sound)
          }
        }
      }
    }
  }
}
</script>
