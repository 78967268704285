import service from "@/Services/TraductionService";
import AppService from "@/Services/AppService";

export default {

    loadLang() {
        this.$store.commit("loadingLanguage", true)

        let lang = (this.preferences.lang || this.informations.defaultLanguage).toLowerCase()

        service.get(lang).then((messages) => {
            
            this.$i18n.setLocaleMessage(lang, messages["interface"])

            this.$i18n.locale = lang == 'ca' ? 'en-ca' : lang
            this.$vuetify.lang.current = lang
            if (lang == "pt")
                this.$vuetify.lang.current = "ptbr"

            window.__langue = lang

            this.$store.commit("loadingLanguage", false)
        })
    },
    getDns() {
        return this.$planeteOnline.dns
    },
    getIconFile(file) {
        let icon = "";
        switch (file.type) {
            case "application/pdf":
                icon = "mdi-file-pdf";
                break;
            case "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
                icon = "mdi-file-excel";
                break;
            case "image/png":
                icon = "mdi-image";
                break;
            case "image/jpeg":
                icon = "mdi-image";
                break;
            case "application/msword":
                icon = "mdi-file-word";
                break;
            case "application/vnd.openxmlformats-officedocument.wordprocessingml.document":
                icon = "mdi-file-word";
                break;
            default:
                icon = "mdi-file";
        }
        return icon;
    },
    async removeDimensionInutileUser(user) {
        delete user.photo;
        delete user.function;
        delete user.quality;
        delete user.defaultLanguage;
        delete user.employee;
        delete user.signature;
        if (!user.superAdministrator)
            delete user.emailAddress;
        delete user.username;
        user.url = this.getDns()
        return user;
    },
    async copyToClipboard(value, successCallBack) {
        await navigator.clipboard.writeText(value)
        if (successCallBack)
            successCallBack(value)
    }
}