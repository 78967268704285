import Api from '@/Services/Api'

const HOST = process.env.VUE_APP_BASEURL ? process.env.VUE_APP_BASEURL : '/planete-online'

export default {
  async isConnected(userToken){
    try {
      let params = {}
      if (userToken) {
        params.userToken = userToken
      }
      let response = await Api().get('/authentication/keep', {params: params})
      return response.status == 200;
    } catch (err) {
      return false
    }
  },

  async login(login, password, locale){
    try {
      let response = await Api().post('/authentication/login', {
        login, password, locale
      }, {
        timeout: 5000,
        withCredentials: true,
        headers: {
          'Content-Type': 'application/json'
        }
      })
      return response.data;
    } catch (err) {
      return console.error(err)
    }
  },

  async loginSSO(){
    try {

      let response = await Api().get('/authentication/saml2/login', {
        timeout: 5000,
        headers: {}
      })
      if (response.data && response.data.login_url) {
        window.location.href = response.data.login_url
      }
    } catch (err) {
      return console.error(err)
    }
  },

  async loginAcountSSO(account){
    let response = await Api().post('/authentication/saml2/login-account', {
      account
    }, 
    {
      timeout: 5000,
      headers: {
        'Content-Type': 'application/json'
      }
    })
    // redirect to the home page
    if (response.data && response.data.url) {
      window.location.href = response.data.url
    }
  },
      
  async getADconfigs(){
    try {
      let response = await Api().get('/authentication/saml2/ad-config', {
        timeout: 5000,
        headers: {}
      })
      return response.data
    } catch (err) {
      return console.error(err)
    }
  },

  async logout(){
    try {
      let response = await Api().get('/authentication/logout', {
        withCredentials: true,
        timeout: 5000,
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        }
      })
      if (response.data.logout_url) {
        window.location.href = response.data.logout_url
      } else {
        return response.data
      }
    } catch (err) {
      return console.error(err)
    }
  },

  async resetPassword(mail, lang) {
    try {
      let response = await Api().post('/authentication/reset-password', {
        lang: lang || "en",
        mail: mail
      }, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        }
      })
      return response.data
    } catch(err) {
      return console.error(err)
    }
  },

  async recoverPassword(token, lang) {
    try {
      let response = await Api().post('/authentication/recover-password', {
        token,
        lang
      }, {
        withCredentials: true,
        headers: {
          "Content-Type": "application/json"
        }
      })
      return response.data
    } catch(err) {
      return console.error(err)
    }
  }
};
