import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework'
import VuetifyColors from "vuetify/lib/util/colors"
import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import PlaneteOnlineColors from "@/Utils/Colors"

import mdi from "@/Assets/Styles/mdi.css"

Vue.use(Vuetify);
Vuetify.config.silent = true

let vuetify = new Vuetify({
    icons: {
        font: "mdi"
    },
    theme: {
        themes: {
            light: {
                primary: PlaneteOnlineColors.primary,
                secondary: PlaneteOnlineColors.secondary,
                tertiary: PlaneteOnlineColors.tertiary,
                backgroundAppColor: VuetifyColors.indigo.lighten5,
                whiteDarkable: VuetifyColors.shades.white,
            },
            dark: {
                primary: PlaneteOnlineColors.primary,
                secondary: PlaneteOnlineColors.secondary,
                tertiary: PlaneteOnlineColors.tertiary,
                backgroundAppColor: "#2a2a2a",
                whiteDarkable: "#5e1d3d"
            },
        },
        options: {
            customProperties: true
        }
    },
    customProperties: true
});

Vue.use(VueTelInputVuetify, {
    vuetify,
});

export default vuetify