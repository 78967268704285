import Vue from "vue"
import VueI18n from "vue-i18n"

import fr from "@/Assets/I18n/langs/fr.json"
import en from "@/Assets/I18n/langs/en.json"

Vue.use(VueI18n)

export default () => {
    const locale = navigator.language.split("-")[0] || 'fr'

    if (en === false || (locale === 'fr' && fr === false)) {
        alert('Failed to load translations, please reload')
    }
    const messages = {
        'en': en !== false && typeof en === 'object' ? en : {},
        'fr': fr !== false && typeof fr === 'object' ? fr : {},
    }
    return new VueI18n({
        locale: locale,
        fallbackLocale: 'en',
        silentFallbackWarn: true,
        messages: messages
    })
}
