export default {
    readableFileSize(bytes, asOctet = false) {
        if (!asOctet) {
            const sizes = ["Bytes", "KB", "MB", "GB", "TB"]
            if (bytes == 0) return "0"

            let i = Math.floor(Math.log(bytes) / Math.log(1024))
            return (bytes / Math.pow(1024, i)).toFixed(2) + " " + sizes[i]
        } else {
            const sizes = ["Octets", "Ko", "Mo", "Go", "To"]
            if (bytes == 0) return "0"

            let i = Math.floor(Math.log(bytes) / Math.log(1000))
            return (bytes / Math.pow(1000, i)).toFixed(2) + " " + sizes[i]
        }
    }
}