<template>
  <v-app id="app">

    <notifications></notifications>
    <sound-player></sound-player>

    <v-scale-transition>
      <router-view></router-view>
    </v-scale-transition>

  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
    Notifications: () => import("@/Components/Commons/Interface/Notifications/Notifications")
  }
};
</script>

<style lang="scss">

</style>