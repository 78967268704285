export default {
    path: "support-vision",
    name: "SupportVision",
    meta: {
        requiresAuth: true,
    },
    component: () => import("@/Components/Views/SupportVision/Index"),
    redirect: {
        name: "SupportVisionHome",
    },
    children: [
        {
            path: "home",
            name: "SupportVisionHome",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Home")
        },
        {
            path: "homepage-message",
            name: "SettingsHomepageMessage",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/HomepageMessage/Index")
        },
        {
            path: "authentication-configuration",
            name: "SettingsAuthenticationConfiguration",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/AuthenticationConfiguration/Index")
        },
        {
            path: "communication",
            name: "SettingsCommunication",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Communication/Index")
        },
        {
            path: "hotline",
            name: "SettingsHotline",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Hotline/Index")
        },
        {
            path: "authentification",
            name: "DoubleAuthentication",
            meta: {
                requiresAuth: true,
            },
            component: () => import("@/Components/Views/Authentification/Index")
        },
        {
            path: "droits",
            name: "SettingsRights",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Droits/Index")
        },
        {
            path: "fetes",
            name: "SettingsParty",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Fêtes/Index")
        },
        {
            path: "clients",
            name: "SettingsClients",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Clients/Index")
        },
        {
          path: "bases",
          name: "SettingsBases",
          meta: {
            requiresAuth: true
          },
          component: () => import("@/Components/Views/SupportVision/Bases/Index")
        },
        { 
            path: "fetes",
            name: "SettingsParty",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Fêtes/Index")
        },
        {
            path: "modules",
            name: "SettingsModules",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Modules/Index")
        },
        {
            path: "developpement",
            name: "SettingsDeveloppement",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Developpement/Index")
        },
        {
            path: "Diffusion",
            name: "SettingsDiffusion",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/DiffusionRepo/Index")
        },
        {
            path: "Cartes",
            name: "SettingsCartes",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Cartes/Index")
        },
        {
            path: "Statistiques",
            name: "SettingsStatistiques",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Statistiques/Index")
        },
        { 
            path: "Parametrage",
            name: "SettingsParametrage",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Parametrage/Index")
        },
        {
            path: "Accueil_dev", 
            name: "SettingsAccueil_dev",
            meta: {
                requiresAuth: true
            },
            component: () => import("@/Components/Views/SupportVision/Developpement/statistiqueFLBloquantes") 
        },
    ]
}
