import Vue from "vue"
import VueNativeNotification from "./vue-native-notification.js"

const Notification = window.Notification || window.webkitNotification || undefined

if (Notification) {
    Vue.use(VueNativeNotification, {
        requestOnNotify: true
    })
    Vue.notification.requestPermission().then()
}