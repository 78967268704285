import Vue from "vue"

import PlaneteIcon from "@/Components/Commons/UiElements/PlaneteIcon"
import VSwitchText from "@/Components/Elements/VuetifyExtend/VSwitchText"
import VTree from "@/Components/Elements/VuetifyExtend/VTree"
import VDatetimePicker from "@/Components/Elements/VuetifyExtend/VDatetimePicker";

Vue.component("PlaneteIcon", PlaneteIcon)
Vue.component("VSwitchText", VSwitchText)
Vue.component("VTree", VTree)
Vue.component("VDatetimePicker", VDatetimePicker)
