import Vue from "vue"
import AppService from "@/Services/AppService"

const DnsConfig = async function (Vue) {
    if (Vue.prototype.$planeteOnline == undefined)
        Vue.prototype.$planeteOnline = {}

    if (Vue.prototype.$planeteOnline.dns == undefined) {
        let hostname = window.location.hostname
        if (hostname == "localhost") {
            hostname = await AppService.getDns()
        }
        Vue.prototype.$planeteOnline.dns = hostname
    }
}

Vue.use(DnsConfig)