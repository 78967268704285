<template>
  <svg
       :width="width"
       :height="height"
       viewBox="0 0 64 64"
       :aria-labelledby="iconName"
  >
    <defs>
      <radialGradient id="radial-gradient" cx="0.029" cy="0.972" r="1.427" gradientTransform="translate(0 0.084) scale(1 0.914)" gradientUnits="objectBoundingBox">
        <stop offset="0" stop-color="#f60037"/>
        <stop offset="0.315" stop-color="#e0165f"/>
        <stop offset="0.432" stop-color="#cb2b86"/>
        <stop offset="0.669" stop-color="#29418d"/>
        <stop offset="0.854" stop-color="#05b8ea"/>
        <stop offset="1" stop-color="#00c8f6"/>
<!--        <stop offset="0" :stop-color="$vuetify.theme.themes.light.tertiary.base"/> &lt;!&ndash; TODO : avoir un getTheme global & avoir une classe de gestion des couleurs et de récupérer (color.base)&ndash;&gt;-->
<!--        <stop offset="1" :stop-color="$vuetify.theme.themes.light.secondary.base"/>-->
      </radialGradient>
    </defs>
    <title :id="iconName">{{ iconName }}</title>

    <g :fill="colorComputed || 'url(#radial-gradient)'">
      <component :is="componentFile"></component>
    </g>
  </svg>
</template>

<script>
export default {
  name: "PlaneteIcon",
  props: {
    width: {
      type: [Number, String],
      default: 18
    },
    height: {
      type: [Number, String],
      default: 18
    },
    color: {
      type: String,
      default: 'gradient'
    },
    leaveEmpty: {
      type: Boolean,
      default: false
    },
    dark: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    colorComputed() {
      if (this.dark) {
        return "white"
      } else {
        if (this.color == "gradient") {
          return "url(#radial-gradient)"
        } else {
          return this.color
        }
      }
    },
    iconName() {
      let componentName = ""
      if (this.$slots.default != undefined && this.$slots.default[0].text != undefined) {
        componentName = this.kebabToPascal(this.$slots.default[0].text)
      }
      if (!componentName && !this.leaveEmpty) componentName = "planete-online"
      return componentName
    },
    componentFile: function () {
      if (this.iconName) {
        return () => import(`@/Components/Commons/Icons/Icon${this.iconName}.vue`)
            .catch(() => import(`@/Components/Commons/Icons/IconPlaneteOnline.vue`))
      }
      return null
    }
  },
}
</script>

<style scoped>

</style>