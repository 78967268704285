import UISoundsComponent from "@/Plugins/Sounds/SoundPlayer";

let planeteOnlineSounds = {
    sounds: {},
    state: {
        sounds: {},
        actionsList: [],
        preferences: {
            soundOn: true
        }
    },
    play: function (slug) {
        this.state.actionsList.push(slug + ":play")
    },
    stop: function (slug) {
        this.state.actionsList.push(slug + ":stop")
    }
}

const install = function (Vue, options) {
    if (options && options.sounds != undefined) {
        planeteOnlineSounds.state.sounds = options.sounds
    }

    Vue.mixin({
        beforeCreate() {
            this.$uiSounds = planeteOnlineSounds
            Vue.$uiSounds = planeteOnlineSounds
        },
        data() {
            return {
                uiSounds: planeteOnlineSounds
            }
        }
    })

    Vue.component("sound-player", UISoundsComponent)
}

export default install