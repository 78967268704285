import Vue from "vue"

import UUID from "vue-uuid"
import Widget from 'vue-smart-widget'
import VueCookie from "vue-cookie"
import Underscore from "vue-underscore"
import Notification from "vt-notifications"
import browserDetect from "vue-browser-detect-plugin"

Vue.use(UUID)
Vue.use(Widget)
Vue.use(VueCookie)
Vue.use(Underscore)
Vue.use(Notification)
Vue.use(browserDetect)
