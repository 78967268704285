import UtilsApp from "../Utils/Application"
import UtilsDev from "../Utils/Dev"
import UtilsImage from "../Utils/Images"
import UtilsLang from "../Utils/Lang"
import UtilsParsing from "../Utils/Parsing"
import UtilsMessagerie from "../Utils/Messagerie"

import ComputedMessagerieColors from "../Utils/MessagerieColors"

export default {
    computed: {
        ...ComputedMessagerieColors
    },
    methods: {
        ...UtilsApp,
        ...UtilsDev,
        ...UtilsImage,
        ...UtilsLang,
        ...UtilsParsing,
        ...UtilsMessagerie
    }
}