export default {
    methods: {
        $nError: function (title, message, time) {
            this.$notify({
                group: "top-right",
                title: title,
                text: message,
                type: "error"
            }, time)
        },
        $nWarning: function (title, message, time) {
            this.$notify({
                group: "top-right",
                title: title,
                text: message,
                type: "warning"
            }, time)
        },
        $nSuccess: function (title, message, time) {
            this.$notify({
                group: "top-right",
                title: title,
                text: message,
                type: "success"
            }, time)
        },
        $nInfo: function (title, message, time) {
            this.$notify({
                group: "top-right",
                title: title,
                text: message,
                type: "info"
            }, time)
        }
    }
}
